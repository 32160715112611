import { RelatedType, WithFields, WithIncludes } from "@mb-pro-ui/utils/types";
import {
  Category as _Category,
  Cdec as _Cdec,
} from "@mb-pro-ui/utils/types/alarm";
import _CustomerCustomFields from "@mb-pro-ui/utils/types/alarm/customer-custom-fields";
import { Intervention as _Intervention } from "./base-types";

export type Intervention = WithIncludes<
  WithFields<_Intervention, typeof interventionsAllFields>,
  typeof interventionIncludes
>;

export type Customer = RelatedType<Intervention["customer"]>;

export const interventionIncludes = {
  customer: {
    "custom-fields": {},
    "interval-rules": {
      "date-code": {},
      "event-category": {},
    },
    maps: {},
    partitions: {},
    "notifiable-persons": {},
    zones: {
      "sensor-type": {},
      "zone-type": {},
    },
  },
  session: { operator: { employee: {} } },
  "task-groups": {
    subtasks: {},
    "action-group": { rules: {} },
  },
  logs: { session: { operator: { employee: {} } } },
};

export const interventionsAllFields = {
  "interventions-all": [
    "start-time",
    "close-time",
    "customer",
    "session",
    "start-event",
    "task-groups",
    "logs",
    "mod-time",
  ],
  customers: [
    "name",
    "account",
    "address",
    "phone",
    "email",
    "mobile",
    "unitid",
    "passwd",
    "active",
    "latitude",
    "longitude",
    "base-latitude",
    "base-longitude",
    "coord-update-ts",
    "base-coord-update-ts",
    "remarks",
    "custom",
    "notifiable-persons",
    "zones",
    "maps",
    "partitions",
    "interval-rules",
    "custom-fields",
    "has-valid-base-coords",
    "has-valid-move-coords",
    "location-name",
  ],
  sessions: ["operator"],
  "log-all": ["time", "text", "automatic", "session"],
  "customer-custom-fields": ["fields"],
  categories: ["localized-description"],
  "date-codes": ["localized-description"],
  "sensor-types": ["localized-description"],
  "zone-types": ["localized-description"],
  operators: ["login", "deleted", "active", "employee"],
  employees: ["name"],
  "subtasks-all": [
    "description",
    "channel",
    "code",
    "person",
    "person-remark",
    "address",
    "status",
  ],
  "action-groups": ["description", "rules"],
  "action-rules": ["category"],
  "task-groups-all": ["status", "action-group", "subtasks"],
} as const;

export type CustomerCustomFields = Omit<_CustomerCustomFields, "name">;

export type TaskGroup = RelatedType<Intervention["task-groups"]>;
export type Subtask = RelatedType<TaskGroup["subtasks"]>;
export type ActionGroup = RelatedType<TaskGroup["action-group"]>;

export const cdecFields = [
  "localized-description",
  "category-alert",
  "color",
  "arrived",
  "sent",
  "latitude",
  "longitude",
  "has-valid-coords",
  "event-code",
  "event-category",
  "account",
  "unitid",
  "partition-number",
  "zone-number",
  "customer",
  "active",
  "event",
  "intervention",
] as const;

export const categoriesFields = ["localized-description"] as const;

export const cdecIncludes = { "event-category": {} } as const;

export type Cdec = WithIncludes<
  WithFields<
    _Cdec,
    { cdec: typeof cdecFields; categories: typeof categoriesFields }
  >,
  typeof cdecIncludes
>;

export type Session = RelatedType<Intervention["session"]>;
export type Operator = RelatedType<Session["operator"]>;

export type Log = RelatedType<Intervention["logs"]>;

export type Partition = RelatedType<Customer["partitions"]>;
export type Zone = RelatedType<Customer["zones"]>;
export type IntervalRule = RelatedType<Customer["interval-rules"]>;
export type Category = Pick<_Category, "id" | "localized-description">;
